import { Suspense, lazy, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';
import { isLoggedIn, isParentLoggedIn, isTentorLoggedIn } from 'src/services/auth.service';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import HomeLayout from 'src/layouts/HomeLayout';
import BlogLayout from 'src/layouts/BlogLayout';
import ReportLayout from 'src/layouts/ReportLayout';
import TentorLayout from 'src/layouts/TentorLayout';

import SuspenseLoader from 'src/components/common/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
const Test = Loader(lazy(() => import('src/content/pages/Test')));

// Home Page
const Home = Loader(lazy(() => import('src/content/pages/home/Home')));
const ProgramsPage = Loader(lazy(() => import('src/content/pages/home/Programs')));
const TryoutsPage = Loader(lazy(() => import('src/content/pages/home/Tryouts')));
const Program = Loader(lazy(() => import('src/content/pages/home/Program')));
const Testimony = Loader(lazy(() => import('src/content/pages/home/Testimony')));
const Testimonial = Loader(lazy(() => import('src/content/pages/home/Testimonial')));
const Campus = Loader(lazy(() => import('src/content/pages/home/Campus')));

// Blog Page
const Blogs = Loader(lazy(() => import('src/content/pages/blog/Blogs')));
const BlogDetail = Loader(lazy(() => import('src/content/pages/blog/BlogDetail')));

// Orders
const Checkout = Loader(lazy(() => import('src/content/pages/order/Checkout')));
const Success = Loader(lazy(() => import('src/content/pages/order/Success')));

// Pages
const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards
const Dashboard = Loader(lazy(() => import('src/content/dashboards')));

// Kelas
const Ecourse = Loader(lazy(() => import('src/content/pages/classes/e-course/Courses')));
const CoursesMembership = Loader(lazy(() => import('src/content/pages/classes/e-course/CoursesMembership')));
const Classes = Loader(lazy(() => import('src/content/pages/classes/classroom/Classes')));
const Schedule = Loader(lazy(() => import('src/content/pages/classes/classroom/Schedule')));
const Tryouts = Loader(lazy(() => import('src/content/pages/classes/tryout/Tryouts')));
const TryoutsMembership = Loader(lazy(() => import('src/content/pages/classes/tryout/TryoutsMembership')));
const Tryout = Loader(lazy(() => import('src/content/pages/classes/tryout/Tryout')));
const StartTryoutPage = Loader(lazy(() => import('src/content/pages/classes/tryout/StartTryoutPage')));
const AnalyticTryout = Loader(lazy(() => import('src/content/pages/classes/tryout/Analytic')));
const Certificate = Loader(lazy(() => import('src/content/pages/classes/tryout/Certificate')));
const RankingPage = Loader(lazy(() => import('src/content/pages/classes/tryout/Ranking')));

// Detail Page
const DetailCourse = Loader(lazy(() => import('src/content/pages/classes/e-course/DetailCourse')));
const CourseDescription = Loader(lazy(() => import('src/content/pages/classes/e-course/CourseDescription')));
const CourseQuiz = Loader(lazy(() => import('src/content/pages/classes/e-course/QuizPage')));


// Other Pages
const Reports = Loader(lazy(() => import('src/content/pages/others/reports/Reports')))
const Ticket = Loader(lazy(() => import('src/content/pages/others/reports/Ticket')))
const Transactions = Loader(lazy(() => import('src/content/pages/others/transactions/Transactions')))
const TransactionDetail = Loader(lazy(() => import('src/content/pages/others/transactions/TransactionDetail')))
const Account = Loader(lazy(() => import('src/content/pages/account')))

// About
const About = Loader(lazy(() => import('src/content/pages/about/About')));
const TermsAndConditions = Loader(lazy(() => import('src/content/pages/about/TermsAndConditions')));
const PrivacyPolicy = Loader(lazy(() => import('src/content/pages/about/PrivacyPolicy')));
const AllMenusMobile = Loader(lazy(() => import('src/content/pages/menu/AllMenusMobile')));

// Auth
const Login = Loader(lazy(() => import('src/content/pages/auth/Login')));
const Register = Loader(lazy(() => import('src/content/pages/auth/Register')));
const Otp = Loader(lazy(() => import('src/content/pages/auth/OTP')));
const OTPRequest = Loader(lazy(() => import('src/content/pages/auth/OTPRequest')));
const ResetPassword = Loader(lazy(() => import('src/content/pages/auth/ResetPassword')));
const AdminLoginAsUser = Loader(lazy(() => import('src/content/pages/auth/AdminLoginAsUser')));
const AuthSuccess = Loader(lazy(() => import('src/content/pages/auth/AuthSuccess')));
const InputPhoneNumber = Loader(lazy(() => import('src/content/pages/auth/InputPhoneNumber')));

// Report
const ReportItems = Loader(lazy(() => import('src/content/pages/report/ReportItems')));
const ReportTryout = Loader(lazy(() => import('src/content/pages/report/ReportTryout')));
const ReportQuiz = Loader(lazy(() => import('src/content/pages/report/ReportQuiz')));
const ReportClassDetail = Loader(lazy(() => import('src/content/pages/report/ReportClassDetail')));
const ReportClassList = Loader(lazy(() => import('src/content/pages/report/ReportClassList')));
const ReportCourseDetail = Loader(lazy(() => import('src/content/pages/report/ReportCourseDetail')));
const ReportCourseList = Loader(lazy(() => import('src/content/pages/report/ReportCourseList')));
const ReportMembershipList = Loader(lazy(() => import('src/content/pages/report/ReportMembershipList')));
const ReportAuth = Loader(lazy(() => import('src/content/pages/report/ReportAuth')));

// Tentor
const AddSchedule = Loader(lazy(() => import('src/content/pages/tentor/AddSchedule')));
const InSchedule = Loader(lazy(() => import('src/content/pages/tentor/InSchedule')));
const ScheduleList = Loader(lazy(() => import('src/content/pages/tentor/ScheduleList')));
const TentorProfile = Loader(lazy(() => import('src/content/pages/tentor/TentorProfile')));

// Status
const Status404 = Loader(lazy(() => import('src/content/pages/Status/Status404')));
const Status500 = Loader(lazy(() => import('src/content/pages/Status/Status500')));
const StatusComingSoon = Loader(lazy(() => import('src/content/pages/Status/ComingSoon')));
const StatusMaintenance = Loader(lazy(() => import('src/content/pages/Status/Maintenance')));

const isUnderMaintenance: boolean = false; // Set to true to enable maintenance mode

type RouterProps = {
  auth: any;
};

const routesMaintenance: RouteObject[] = [
  {
    path: '*',
    element: <StatusMaintenance />
  }
];

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <HomeLayout />,
        children: [
          {
            path: '',
            element: <Home />
          },
          {
            path: 'programs',
            element: <ProgramsPage />
          },
          {
            path: 'testimony',
            element: <Testimony />
          },
          {
            path: 'testimonials',
            element: <Testimonial />
          },
          {
            path: 'program/:id',
            element: <Program />
          },
          {
            path: 'kampus/:slug',
            element: <Program />
          },
          {
            path: 'blogs',
            element: <Blogs />
          },
          {
            path: 'checkout/:id',
            element: <Checkout />
          },
          {
            path: 'success-payment',
            element: <Success />
          },
          {
            path: 'ytta/program/:id',
            element: <Program />
          },
          {
            path: 'ytta/programs',
            element: <ProgramsPage />
          },
          {
            path: 'privacy-policy',
            element: <PrivacyPolicy />
          },
          {
            path: 'terms-of-services',
            element: <TermsAndConditions />
          },
          {
            path: 'about',
            element: <About />
          }
        ]
      },
      {
        path: 'blog/:slug',
        element: <BlogLayout />,
        children: [
          {
            path: '',
            element: <BlogDetail />
          }
        ]
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      },
      {
        path: 'classes',
        element: <SidebarLayout />,
        children: isLoggedIn() ? [
          {
            path: '',
            element: <Navigate to="e-course" replace />
          },
          {
            path: 'e-course',
            children: [
              {
                path: '',
                element: <Ecourse />
              },
              {
                path: 'membership/:membershipId',
                element: <CoursesMembership />
              },
              {
                path: ':membershipId/:courseId',
                element: <CourseDescription />
              },
              {
                path: ':membershipId/:courseId/:materiId/:id',
                element: <DetailCourse />
              },
            ]
          },
          {
            path: 'classroom',
            children: [
              {
                path: '',
                element: <Classes />
              },
              {
                path: ':classId/schedule',
                element: <Schedule />
              },
              {
                path: ':classId/:courseId/:materiId',
                element: <DetailCourse />
              },
              {
                path: ':classId/:courseId/:materiId/:id',
                element: <DetailCourse />
              },
            ]
          },
          {
            path: 'tryout',
            children: [
              {
                path: '',
                element: <Tryouts />
              },
              {
                path: 'membership/:membershipId',
                element: <TryoutsMembership />
              },
              {
                path: ':id',
                element: <Tryout />
              },
              {
                path: ':id/start',
                element: <StartTryoutPage />
              },
              {
                path: ':id/detail',
                element: <DetailCourse />
              },
              {
                path: ':id/analytic',
                element: <AnalyticTryout />
              },
                            {
                path: ':id/ranking',
                element: <RankingPage />
              }
            ]
          }
        ] : [
          {
            path: '',
            element: <Navigate to="/login" replace />
          }
        ]
      },
      { 
        path: 'others',
        element: <SidebarLayout />,
        children: isLoggedIn() ? [
          {
            path: '',
            element: <Navigate to="reports" replace />
          },
          {
            path: 'reports',
            element: <Reports />
          },
          {
            path: 'reports/:id',
            element: <Ticket />
          },
          {
            path: 'transactions',
            element: <Transactions />
          },
          {
            path: 'transactions/:id',
            element: <TransactionDetail />
          },
          {
            path: 'account',
            element: <Account />
          }
        ] : [
          {
            path: '',
            element: <Navigate to="/login" replace />
          }
        ]
      },
      {
        path: 'all-menu',
        element: <SidebarLayout />,
        children: [
          {
            path: '',
            element: <AllMenusMobile />
          }]
        
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: '/auth',
    element: <BaseLayout />,
    children: [
      {
        path: 'register',
        element: <Register />
      },
      {
        path: 'otp',
        element: <Otp />
      },
      {
        path: 'otp-request',
        element: <OTPRequest />
      },
      {
        path: 'reset-password',
        element: <ResetPassword />
      },
      {
        path: 'as-user/success',
        element: <AdminLoginAsUser />
      },
      {
        path: 'google/success',
        element: <AuthSuccess />
      },
      {
        path: 'input-phone',
        element: <InputPhoneNumber />
      }
    ]
  },
  {
    path: '/login',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <Login />
      }
    ]
  },
  {
    path: 'utils',
    element: <BaseLayout />,
    children: isLoggedIn() ? [
      {
        path: 'certificate',
        element: <Certificate />
      },
    ] : [
      {
        path: '',
        element: <Navigate to="/login" replace />
      }
    ]
  },
  {
   path: 'report/member/:token',
    element: <ReportLayout />,
    children: [
      {
        path: '',
        element: <ReportAuth />
      },
    ] 
  },
  {
    path: 'report',
    element: <ReportLayout />,
    children: isParentLoggedIn() ? [
      {
        path: 'membership',
        element: <ReportMembershipList />
      },
      {
        path: 'membership/:id',
        element: <ReportItems />
      },
      {
        path: ':membershipId/class',
        element: <ReportClassList />
      },
      {
        path: ':membershipId/class/:classId',
        element: <ReportClassDetail />
      },
      {
        path: ':membershipId/course',
        element: <ReportCourseList />
      },
      {
        path: ':membershipId/course/:courseId',
        element: <ReportCourseDetail />
      },
      {
        path: ':membershipId/tryout',
        element: <ReportTryout />
      },
      {
        path: ':membershipId/quiz',
        element: <ReportQuiz />
      }
    ] : [
      {
        path: '',
        element: <Navigate to="/login" replace />
      }
    ]
  },
  {
    path: 'tentor',
    element: <TentorLayout />,
    children: isTentorLoggedIn() ? [
      {
        path: 'add-schedule',
        element: <AddSchedule />
      },
      {
        path: 'in-schedule',
        element: <InSchedule />
      },
      {
        path: 'schedule',
        element: <ScheduleList />
      },
      {
        path: 'profile',
        element: <TentorProfile />
      }
    ] : [
      {
        path: '',
        element: <Navigate to="/login" replace />
      }
    ]
  },
  {
    path: 'kampus/:event',
    element: <Campus />,
  },
  {
    path: 'test',
    element: <SidebarLayout />,
    children:  [
      {
        path: '',
        element: <Test/>
      },
    ] 
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: isLoggedIn() ? [
      {
        path: '',
        element: <Dashboard/>
      }
    ] : [
      {
        path: '',
        element: <Navigate to="/login" replace />
      }
    ]
  },
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      }
    ]
  },
];

export default isUnderMaintenance ? routesMaintenance : routes;
